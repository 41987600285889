define("discourse/plugins/discourse-journal/discourse/templates/connectors/after-topic-footer-main-buttons/entry-button-wrapper", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @outletArgs.topic.can_create_entry}}
    <DButton
      class="btn-primary create entry"
      @icon="reply"
      @action={{action "createEntry"}}
      @label="topic.entry.title"
      @title="topic.entry.title"
    />
  {{/if}}
  */
  {
    "id": "WK2Ow5z5",
    "block": "[[[41,[30,1,[\"topic\",\"can_create_entry\"]],[[[1,\"  \"],[8,[39,1],[[24,0,\"btn-primary create entry\"]],[[\"@icon\",\"@action\",\"@label\",\"@title\"],[\"reply\",[28,[37,2],[[30,0],\"createEntry\"],null],\"topic.entry.title\",\"topic.entry.title\"]],null],[1,\"\\n\"]],[]],null]],[\"@outletArgs\"],false,[\"if\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-journal/discourse/templates/connectors/after-topic-footer-main-buttons/entry-button-wrapper.hbs",
    "isStrictMode": false
  });
});