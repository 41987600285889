define("discourse/plugins/discourse-journal/discourse/components/journal-group-chooser", ["exports", "discourse/lib/decorators", "select-kit/components/group-chooser"], function (_exports, _decorators, _groupChooser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _groupChooser.default.extend(dt7948.p({
    valueProperty: "name",
    labelProperty: "name",
    didReceiveAttrs() {
      this._super(...arguments);
      const category = this.get("category");
      if (category.custom_fields?.journal_author_groups) {
        this.set("value", category.custom_fields.journal_author_groups.split("|").filter(a => a.length !== ""));
      }
    },
    content(siteGroups) {
      return siteGroups;
    },
    actions: {
      onChange(authorGroups) {
        const category = this.get("category");
        const customFields = category.custom_fields || {};
        customFields["journal_author_groups"] = authorGroups.join("|");
        this.setProperties({
          value: authorGroups,
          "category.custom_fields": customFields
        });
      }
    }
  }, [["method", "content", [(0, _decorators.default)("site.groups")]]]));
});