define("discourse/plugins/discourse-journal/discourse/templates/connectors/category-custom-settings/enable-journal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <section>
    <h3>{{i18n "category.journal_settings_label"}}</h3>
  
    <section class="field">
      <label>
        <Input
          id="enable-journal-for-category"
          @type="checkbox"
          @checked={{@outletArgs.category.custom_fields.journal}}
        />
        {{i18n "category.enable_journal"}}
      </label>
    </section>
  
    <section class="field">
      <label for="category-journal-authors">
        {{i18n "category.journal_authors"}}
      </label>
      <JournalGroupChooser @category={{@outletArgs.category}} />
  
    </section>
  
    <section class="field">
      <h4 for="category-journal-update-sort-order">
        {{i18n "category.update_sort_order.label"}}
      </h4>
  
      <p>{{i18n "category.update_sort_order.description"}}</p>
  
      <DButton
        @label="category.update_sort_order.button"
        @action={{this.updateSortOrder}}
        @icon="arrows-rotate"
      />
  
      {{#if this.syncResultIcon}}
        {{d-icon this.syncResultIcon}}
      {{else}}
        {{conditional-loading-spinner
          condition=this.updatingSortOrder
          size="small"
        }}
      {{/if}}
    </section>
  </section>
  */
  {
    "id": "A2mgvSa8",
    "block": "[[[10,\"section\"],[12],[1,\"\\n  \"],[10,\"h3\"],[12],[1,[28,[35,2],[\"category.journal_settings_label\"],null]],[13],[1,\"\\n\\n  \"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,\"\\n      \"],[8,[39,4],[[24,1,\"enable-journal-for-category\"]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,1,[\"category\",\"custom_fields\",\"journal\"]]]],null],[1,\"\\n      \"],[1,[28,[35,2],[\"category.enable_journal\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,\"for\",\"category-journal-authors\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"category.journal_authors\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,5],null,[[\"@category\"],[[30,1,[\"category\"]]]],null],[1,\"\\n\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n    \"],[10,\"h4\"],[14,\"for\",\"category-journal-update-sort-order\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"category.update_sort_order.label\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,2],[12],[1,[28,[35,2],[\"category.update_sort_order.description\"],null]],[13],[1,\"\\n\\n    \"],[8,[39,8],null,[[\"@label\",\"@action\",\"@icon\"],[\"category.update_sort_order.button\",[30,0,[\"updateSortOrder\"]],\"arrows-rotate\"]],null],[1,\"\\n\\n\"],[41,[30,0,[\"syncResultIcon\"]],[[[1,\"      \"],[1,[28,[35,10],[[30,0,[\"syncResultIcon\"]]],null]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,11],null,[[\"condition\",\"size\"],[[30,0,[\"updatingSortOrder\"]],\"small\"]]]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@outletArgs\"],false,[\"section\",\"h3\",\"i18n\",\"label\",\"input\",\"journal-group-chooser\",\"h4\",\"p\",\"d-button\",\"if\",\"d-icon\",\"conditional-loading-spinner\"]]",
    "moduleName": "discourse/plugins/discourse-journal/discourse/templates/connectors/category-custom-settings/enable-journal.hbs",
    "isStrictMode": false
  });
});