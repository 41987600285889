define("discourse/plugins/discourse-journal/discourse/components/journal-comment-button", ["exports", "@glimmer/component", "discourse/components/d-button", "@ember/object", "discourse/models/composer", "@ember/owner", "@ember/component", "@ember/template-factory"], function (_exports, _component, _dButton, _object, _composer, _owner, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class JournalCommentButton extends _component.default {
    static hidden() {
      return false;
    }
    get i18nKey() {
      return this.args.post.reply_to_post_number ? "comment_reply" : "comment";
    }
    get icon() {
      return this.args.post.reply_to_post_number ? "reply" : "comment";
    }
    get label() {
      if (!this.args.post.mobileView && !this.args.post.reply_to_post_number) {
        return `topic.${this.i18nKey}.title`;
      } else {
        return "";
      }
    }
    get title() {
      return `topic.${this.i18nKey}.help`;
    }
    openCommentCompose() {
      const opts = {
        action: _composer.default.REPLY,
        draftKey: this.args.post.topic.get("draft_key"),
        draftSequence: this.args.post.topic.get("draft_sequence"),
        post: this.args.post
      };
      (0, _owner.getOwner)(this).lookup("service:composer").open(opts);
    }
    static #_ = (() => dt7948.n(this.prototype, "openCommentCompose", [_object.action]))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          class="comment create fade-out"
          ...attributes
          @action={{this.openCommentCompose}}
          @icon={{this.icon}}
          @label={{this.label}}
          @title={{this.title}}
        />
      
    */
    {
      "id": "akw401+j",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"comment create fade-out\"],[17,1]],[[\"@action\",\"@icon\",\"@label\",\"@title\"],[[30,0,[\"openCommentCompose\"]],[30,0,[\"icon\"]],[30,0,[\"label\"]],[30,0,[\"title\"]]]],null],[1,\"\\n  \"]],[\"&attrs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-journal/discourse/components/journal-comment-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = JournalCommentButton;
});