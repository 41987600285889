define("discourse/plugins/discourse-journal/discourse/templates/connectors/topic-category/journal-tip-container", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @outletArgs.topic.showJournalTip}}
    <JournalTopicTip
      @label="topic.tip.journal.title"
      @details="topic.tip.journal.details"
    />
  {{/if}}
  */
  {
    "id": "AenInrxQ",
    "block": "[[[41,[30,1,[\"topic\",\"showJournalTip\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@label\",\"@details\"],[\"topic.tip.journal.title\",\"topic.tip.journal.details\"]],null],[1,\"\\n\"]],[]],null]],[\"@outletArgs\"],false,[\"if\",\"journal-topic-tip\"]]",
    "moduleName": "discourse/plugins/discourse-journal/discourse/templates/connectors/topic-category/journal-tip-container.hbs",
    "isStrictMode": false
  });
});